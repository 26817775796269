import { useState } from 'react';
import { useTokens, useTokensDispatch } from './TokensContext.js';

export default function JSONview(props) {
    // const jsonView = document.getElementById('json-view');

    return (
        <div
            id='json-view'>
            <h2>Tokens JSON</h2>
            <textarea id='json-text' defaultValue={props.jsonText}/>
            <button onClick={() => {
                props.setJsonViewVisible(false)
            }}>Done</button>
        </div>
    )
}