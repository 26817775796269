import { useState } from 'react';
import { useTokensDispatch } from './TokensContext.js';

export default function AddToken() {
  const [colorName, setColorName] = useState('');
  const [colorValue, setColorValue] = useState('');
  const dispatch = useTokensDispatch();

  return (
    <>
      <input
        placeholder="Add color name"
        value={colorName}
        onChange={e => setColorName(e.target.value)}
      />
      <input
        placeholder="Add color value"
        value={colorValue}
        onChange={e => setColorValue(e.target.value)}
      />
      <button onClick={() => {
        setColorName('');
        dispatch({
          type: 'added',
          id: colorName,
          colorName: colorName,
          colorValue: colorValue
        }); 
      }}>Add</button>
    </>
  );
}

