import { useState, useRef } from 'react';
import { useTokens, useTokensDispatch } from './TokensContext.js';


export default function TokenList() {
  const tokens = useTokens();
  const newTokenList = [];


  return (
    <ul>
      {tokens.list.map(token => (
        <li key={token.id}>
          <Token token={token} />
        </li>
      ))}
    </ul>
  );

}

function Token({ token }) {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useTokensDispatch();
  const colorValueRef = useRef(null);
  let tokenContent;
  
  if (isEditing) {
    tokenContent = (
      <>
        <input
          className='colorName'
          defaultValue={token.colorName}
          onBlur={e => {
            // When we change the name, we're really deleting the old node and creating a new one
            // we're using an uncontrolled input here so that we're not firing off updates
            // while user is updating name
            dispatch({
              type: 'added',
              id: e.target.value,
              colorName: e.target.value,
              colorValue: colorValueRef.current.value
            });
            dispatch({
              type: 'deleted',
              id: e.target.defaultValue
            });
          }} />
        <input
          className='colorValue'
          ref={colorValueRef}
          value={token.colorValue}
          onChange={e => {
            dispatch({
              type: 'changed',
              token: {
                ...token,
                colorValue: e.target.value
              }
            });
          }} />
        <button onClick={() => setIsEditing(false)}>
          Save
        </button>
      </>
    );
  } else {
    tokenContent = (
      <>
        <span className='colorName'>{token.colorName}</span>
        <span className='colorValue'>{token.colorValue}</span>
        <button onClick={() => setIsEditing(true)}>
          Edit
        </button>
      </>
    );
  }
  return (
    <label>
      {tokenContent}
      <button onClick={() => {
        dispatch({
          type: 'deleted',
          id: token.id
        });
      }}>
        Delete
      </button>
    </label>
  );
}
