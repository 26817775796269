import { useState } from 'react';
import { useTokens, useTokensDispatch } from './TokensContext.js';

export default function Header(props) {
    const tokens = useTokens();

    return (
        <div
            className='header'>
            <h1>AXIS Design System Token Generator</h1>
            <button onClick={() => {
                // console.log(jsonView);
                props.setJsonViewVisible(true)
                const jsonText = JSON.stringify(tokens.objectStyle, null, 4)
                props.setJsonText(jsonText)
                console.log(tokens);
            }}>JSON...</button>
        </div>
    )
}