import AddToken from './AddToken.js';
import TokenList from './TokenList.js';
import JSONview from './JSONview';
import Header from './Header.js';
import { TokensProvider } from './TokensContext.js';
import { useState } from 'react';

export default function TokenApp() {
  const [jsonViewVisible, setJsonViewVisible] = useState(false);
  const [jsonText, setJsonText] = useState("test json text");


  return (
    <TokensProvider>
      <Header
        setJsonViewVisible={setJsonViewVisible}
        setJsonText={setJsonText}
      />
      <AddToken />
      {!jsonViewVisible && <TokenList />}
      {
        jsonViewVisible && <JSONview
          setJsonViewVisible={setJsonViewVisible}
          jsonText={jsonText}
          setJsonText={setJsonText}
        />
      }
    </TokensProvider>
  );
}
