import { createContext, useContext, useReducer } from 'react';
import defaultJson from './defaultTokens.json';
const initialTokens = {
  objectStyle: defaultJson
}


const JSONContext = createContext(null);

const TokensDispatchContext = createContext(null);

export function TokensProvider({ children }) {

  // derive Array list from JSON object
  initialTokens.list = returnFlattenedNodes("root", [], initialTokens.objectStyle)
  
  const [tokens, dispatch] = useReducer(
    tokensReducer,
    initialTokens
  );

  return (
    <JSONContext.Provider value={tokens}>
      <TokensDispatchContext.Provider value={dispatch}>
        {children}
      </TokensDispatchContext.Provider>
    </JSONContext.Provider>
  );
}

export function useTokens() {
  return useContext(JSONContext);
}

export function useTokensDispatch() {
  return useContext(TokensDispatchContext);
}

function returnFlattenedNodes(path, tokenList, tokensObj) {
  if ('$value' in tokensObj) {
    tokenList.push({
      "id": path,
      "colorName": path,
      "colorValue": tokensObj["$value"]
    })
    return tokenList
  } else {
    for (const key in tokensObj) {
      if ((key == '$type') || (key == '$description')){
        // do nothing and return
      } else returnFlattenedNodes(path + "." + key, tokenList, tokensObj[key])
    }
  }
  return tokenList
}

function nodeDive(nodePathArray, tokensObj) {
  const currentKey = nodePathArray.shift();

  //if this branch doesn't already exist, build it out
  if (!(currentKey in tokensObj)) {
    tokensObj[currentKey] = {}
  }

  if (nodePathArray.length == 0) {
    return tokensObj[currentKey];
  } else {
    return nodeDive(nodePathArray, tokensObj[currentKey]);
  }
}

function returnNode(nodePath, tokensObject) {
  const nodePathArray = nodePath.split('.');
  let returnNode = nodeDive(nodePathArray,tokensObject);
  return returnNode
}

function tokensReducer(tokens, action) {
  switch (action.type) {
    case 'added': {
      //remove root. from color name
      const nodePath = action.colorName.replace('root.', '');
      
      let node = returnNode(nodePath, tokens.objectStyle);
      node['$value'] = action.colorValue;

      let newNewTokenList = returnFlattenedNodes("root", [], tokens.objectStyle);
      tokens.list = newNewTokenList;
     
      // shallow copy below is necessary, because otherwise React doesn't think the context has changed (returned object must be different to previous)
      // seems a bit of a hack, but performance not really an issue for this prototype
      const newTokens = Object.assign({}, tokens);
      return newTokens;
    }
    case 'changed': {
      // get node to update
      const nodePath = action.token.colorName.replace('root.', '');
      let node = returnNode(nodePath, tokens.objectStyle);

      //update node
      node['$value'] = action.token.colorValue;
      
      // update token list based on object
      tokens.list = returnFlattenedNodes("root", [], tokens.objectStyle);

      // shallow copy below is necessary, because otherwise React doesn't think the context has changed (returned object must be different to previous)
      // seems a bit of a hack, but performance not really an issue for this prototype
      const newTokens = Object.assign({}, tokens);
      return newTokens
    }
    case 'deleted': {
      // get parent node

      const nodePath = action.id.replace('root.', '');
      let nodePathArray = nodePath.split('.');
      const key = nodePathArray.pop();
      const parentNodePath = nodePathArray.join('.');

      let parentNode = returnNode(parentNodePath, tokens.objectStyle);

      //delete node
      delete parentNode[key];
      
      // update token list based on object
      tokens.list = returnFlattenedNodes("root", [], tokens.objectStyle);

      // shallow copy below is necessary, because otherwise React doesn't think the context has changed (returned object must be different to previous)
      // seems a bit of a hack, but performance not really an issue for this prototype
      const newTokens = Object.assign({}, tokens);
      return newTokens
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}
